import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';
import type { HandleClientError, HandleServerError, MaybePromise } from '@sveltejs/kit';

Sentry.init({
	dsn: 'https://bc9c934a1f771969a465a76b10cb377d@o300246.ingest.sentry.io/4506520958402560',
	enabled: !dev,
	integrations: []
});

const handleErrorDev: HandleClientError = (input) => {
	return {
		message: input.message,
		error: input.error instanceof Error ? input.error.toString() : undefined,
		stack: input.error instanceof Error ? input.error.stack : undefined
	};
};

export const handleError = handleErrorWithSentry(dev ? handleErrorDev : undefined);
