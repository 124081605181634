import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [];

export const dictionary = {
		"/(std)": [~8],
		"/about/[slug]": [~24],
		"/admin/books": [~25,[6]],
		"/admin/books/[book_id]": [~26,[6]],
		"/admin/pages": [~27,[6]],
		"/admin/pages/[page_id]": [~28,[6]],
		"/admin/users": [~29,[6]],
		"/admin/users/[user_id]": [~30,[6]],
		"/(std)/books": [9,[2]],
		"/(std)/books/[book_id]": [10,[2,3]],
		"/(std)/books/[book_id]/accept/[invite_id]": [11,[2,3]],
		"/(std)/books/[book_id]/bibliography": [12,[2,3]],
		"/(std)/books/[book_id]/chapters/[chapter_id]": [13,[2,3]],
		"/(std)/books/[book_id]/debug/mapping": [14,[2,3,4]],
		"/(std)/books/[book_id]/debug/math": [15,[2,3,4]],
		"/(std)/books/[book_id]/debug/math/[eq]": [16,[2,3,4]],
		"/(std)/books/[book_id]/debug/typst": [17,[2,3,4]],
		"/(std)/books/[book_id]/index": [18,[2,3]],
		"/(std)/books/[book_id]/notes": [19,[2,3]],
		"/(std)/books/[book_id]/title": [20,[2,3]],
		"/(std)/books/[book_id]/toc": [21,[2,3]],
		"/sentry-example": [31],
		"/(std)/settings": [~22,[5]],
		"/(std)/settings/link/finished": [23,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';